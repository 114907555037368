<template>
  <wizard ref="wizard" v-slot="{ goPrev, getStep }" :clickable-steps="false">
    <wizard-nav>
      <wizard-nav-step :title="$t('Nations')" desc="Setup nation" number="1" />
      <wizard-nav-step :title="$t('Contracts Comparator')" desc="Add Contractor" number="2" />
      <wizard-nav-step :title="$t('Level Association')" desc="Setup level" number="3" />
    </wizard-nav>
    <validation-observer ref="contractComparisonValidationObserver" v-slot="{ handleSubmit }">
      <wizard-body>
        <wizard-step>
          <div v-if="getStep() === 1">
            <validation-observer ref="validator-1">
              <div class="form row">
                <div class="col-6 border-right">
                  <h6>{{ $t("Choose Nation 1") }}</h6>
                  <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="nation_1_country"
                    rules="required">
                    <euro-select v-model="nation1.country" :placeholder="$t('Select a Nation')"
                      :options="countriesSelectable" :error-messages="errors" searchable></euro-select>
                  </validation-provider>

                  <h6>{{ $t("Employment Contract") }}</h6>
                  <validation-provider v-slot="{ errors }" :name="$t('Contract')" vid="nation_1_contract"
                    rules="required">
                    <euro-select v-model="nation1.contract" :placeholder="$t('Select a Contract')" :multiselect-props="{
    options: nation1Contracts,
    label: 'name',
    'track-by': 'id',
  }" return-object :error-messages="errors" searchable></euro-select>
                  </validation-provider>
                </div>
                <div class="col-6">
                  <h6>{{ $t("Choose Nation 2") }}</h6>
                  <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="nation_2_country"
                    rules="required">
                    <euro-select v-model="nation2.country" :placeholder="$t('Select a Nation')"
                      :options="countriesSelectable" :error-messages="errors" searchable></euro-select>
                  </validation-provider>
                  <h6>{{ $t("Employment Contract") }}</h6>
                  <validation-provider v-slot="{ errors }" :name="$t('Contract')" vid="nation_2_contract"
                    rules="required">
                    <euro-select v-model="nation2.contract" :placeholder="$t('Select a Contract')" :multiselect-props="{
    options: nation2Contracts,
    label: 'name',
    'track-by': 'id',
  }" return-object :error-messages="errors" searchable></euro-select>
                  </validation-provider>
                </div>
              </div>
            </validation-observer>
          </div>
        </wizard-step>

        <wizard-step>
          <div v-if="getStep() === 2">
            <!-- TODO: extract this table in a component when we have APIs -->
            <div class="form row">
              <div class="col-6 border-right">
                <div class="d-flex align-items-center mb-4">
                  <country-flag :country-iso="nation1?.contract?.country"></country-flag>
                  <h6 class="my-0 ml-4">{{ nation1?.contract?.name }}</h6>
                </div>
                <datatable :table-props="{
    items: nation1ContractLevels,
    fields: nation1.comparatorTable.fields,
  }" :options.sync="nation1.comparatorTable.options">
                  <template #[`cell.national_legislation`]="{ item }">
                    <div class="d-flex align-items-center">
                      <country-flag :country-iso="item.iso" class="mr-3"></country-flag>
                      {{ item.national_legislation }}
                    </div>
                  </template>
                </datatable>
              </div>

              <div class="col-6">
                <div class="d-flex align-items-center mb-4">
                  <country-flag :country-iso="nation2?.contract?.country"></country-flag>
                  <h6 class="my-0 ml-4">{{ nation2?.contract?.name }}</h6>
                </div>
                <datatable :table-props="{
    items: nation2ContractLevels,
    fields: nation2.comparatorTable.fields,
  }" :options.sync="nation2.comparatorTable.options">
                  <template #[`cell.national_legislation`]="{ item }">
                    <div class="font-weight-bolder h-100">
                      <country-flag :country-iso="item.iso" class="mr-3"></country-flag>
                      {{ item.national_legislation }}
                    </div>
                  </template>
                </datatable>
              </div>
            </div>
          </div>
        </wizard-step>

        <wizard-step>
          <div v-if="getStep() === 3">
            <validation-observer ref="validator-3">
              <div class="form row">
                <div class="col-2"></div>
                <div class="col-5">
                  <h5>
                    <country-flag :country-iso="nation1.country"></country-flag>
                    {{ countriesMap[nation1.country].name }}
                  </h5>
                </div>
                <div class="col-5">
                  <h5>
                    <country-flag :country-iso="nation2.country"></country-flag>
                    {{ countriesMap[nation2.country].name }}
                  </h5>
                </div>
              </div>
              <div v-for="level in levelsForm" :key="level.parentLevel.id" class="form row">
                <div class="col-2">
                  <h6>{{ $t("Level") }} {{ level.parentLevel.level }}</h6>
                </div>
                <div class="col-5">
                  <euro-select :value="level.parentLevel" return-object :multiselect-props="{
    disabled: true,
    options: nation1ContractLevels,
    label: '_customName',
    'track-by': 'id',
  }">
                    <template #singleLabel>
                      {{ levelHumanReadableName(level.parentLevel) }}
                    </template>
                  </euro-select>
                </div>
                <div class="col-5">
                  <validation-provider v-slot="{ errors }" :name="$t('Level')"
                    :vid="`nation_2_level_${level.parentLevel.level}`" rules="required">
                    <euro-select v-model="level.childLevels" :multiselect-props="{
    options: nation2ContractLevels,
    label: '_customName',
    'track-by': 'id',
    multiple: true,
  }" :error-messages="errors">
                      <template #singleLabel="{ option }">
                        {{ levelHumanReadableName(option) }}
                      </template>
                    </euro-select>
                  </validation-provider>
                </div>
                <div class="col-12">
                  <div class="separator separator-dashed mb-7 mt-0"></div>
                </div>
              </div>
            </validation-observer>
          </div>
        </wizard-step>

        <wizard-actions>
          <div class="mr-2">
            <wizard-prev-btn :disabled="submitting" @click="goPrev"></wizard-prev-btn>
          </div>
          <div>
            <wizard-submit-btn :disabled="submitting" @click="handleSubmit(submit)"></wizard-submit-btn>
            <wizard-next-btn :disabled="submitting" @click="handleNextStep"></wizard-next-btn>
          </div>
        </wizard-actions>
      </wizard-body>
    </validation-observer>
  </wizard>
</template>

<script>
import EmployeeNationalContractService from "@/core/services/employee/employee-nationalcontract.service";
import _ from "lodash";

import CountryFlag from "@/view/components/CountryFlag.vue";
import Datatable from "@/view/components/tables/Datatable.vue";
import { mapState, mapGetters } from "vuex";
import { updateCorrespondingLevels } from "@/core/abstractions/contracts";
import { backendErrorSwal } from "@/core/helpers/swal";
import { successToast } from '@/core/helpers';

export default {
  components: {
    Datatable,
    CountryFlag,
  },

  data() {
    return {
      nation1: {
        country: null,
        contract: null,
        comparatorTable: {
          options: {
            sortBy: "level",
            sortDesc: true,
            perPage: 5,
            currentPage: 1,
          },
          fields: [
            { label: this.$t("Level"), key: "level", sortable: true, class: "align-middle" },
            { label: this.$t("Level Name"), key: "level_name", sortable: true, class: "align-middle" },
            { label: this.$t("Base Pay"), key: "base_pay", sortable: true, class: "align-middle" },
          ],
        },
      },
      nation2: {
        country: null,
        contract: null,
        comparatorTable: {
          options: {
            sortBy: "level",
            sortDesc: true,
            perPage: 5,
            currentPage: 1,
          },
          fields: [
            { label: this.$t("Level"), key: "level", sortable: true, class: "align-middle" },
            { label: this.$t("Level Name"), key: "level_name", sortable: true, class: "align-middle" },
            { label: this.$t("Base Pay"), key: "base_pay", sortable: true, class: "align-middle" },
          ],
        },
      },
      contracts: [],
      // Each index is a level from nation1 ordered by level.
      levelsForm: [],
      submitting: false,
    };
  },

  computed: {
    ...mapGetters("constants", ["countriesMap"]),

    selectedContracts() {
      return [this.nation1.contract, this.nation2.contract];
    },

    contractsMap() {
      return this.contracts.reduce((acc, curr) => acc.set(curr.id, curr), new Map());
    },

    ...mapState("constants", ["countries"]),

    countriesSelectable() {
      const contractCountryIso = _.uniq(this.contracts.map(el => el.country));

      return contractCountryIso.map(el => {
        const country = this.countries.find(x => x.iso == el);
        return {
          value: el,
          text: country?.name ?? el,
          $isDisabled: el == this.nation1.country || el == this.nation2.country,
        };
      });
    },

    nation1Contracts() {
      return this.contracts.filter(el => el.country == this.nation1.country);
    },

    nation1ContractLevels() {
      if (!this.nation1.contract) return [];
      return this.nation1.contract.nationalcontractlevel_set.map(level => ({
        ...level,
        _customName: this.levelHumanReadableName(level),
      }));
    },

    nation2Contracts() {
      return this.contracts.filter(el => el.country == this.nation2.country);
    },

    nation2ContractLevels() {
      if (!this.nation2.contract) return [];

      return this.nation2.contract.nationalcontractlevel_set.map(level => ({
        ...level,
        _customName: this.levelHumanReadableName(level),
      }));
    },

    nation2ContractLevelsIds() {
      return this.nation2ContractLevels.map(el => el.id);
    },
  },

  watch: {
    "nation1.country"() {
      this.nation1.contract = null;
    },

    "nation1.contract"(contract) {
      if (!contract) {
        this.levelsForm = [];
        return;
      }

      const { nationalcontractlevel_set: levels } = contract;
      // Order the levels of nations one contract and build structure for final association form.
      levels
        .sort((a, b) => a.level - b.level)
        .forEach(level => {
          this.levelsForm.push({
            // The nation 1 level
            parentLevel: level,
            // The associated levels. Can be multiple.
            childLevels: this.getChildLevelsIds(level),
          });
        });
    },

    "nation2.country"() {
      this.nation2.contract = null;
    },

    "nation2.contract"() {
      if (!this.levelsForm.length) {
        return;
      }

      this.levelsForm = this.levelsForm.map(levelForm => {
        return {
          ...levelForm,
          childLevels: this.getChildLevelsIds(levelForm.parentLevel),
        };
      });
    },
  },

  mounted() {
    this.loadContracts()
  },

  methods: {
    getChildLevelsIds(parentLevel) {
      return this.nation2ContractLevels
        .filter(nation2Level => parentLevel.level_corresponding.includes(nation2Level.id))
        .map(level => level.id);
    },

    levelHumanReadableName(level) {
      return `${level.level_name} - ${level.base_pay} ${level.base_pay_currency}/h`;
    },

    async handleNextStep() {
      const wizard = this.$refs.wizard;
      const step = wizard.getStep();

      if (step == 2) {
        wizard.goNext();
        return;
      }
      const validator = this.$refs[`validator-${this.$refs.wizard.getStep()}`];
      const isValid = await validator.validate();
      if (!isValid) {
        return;
      }
      wizard.goNext();
    },

    async submit() {
      this.submitting = true;
      try {
        const results = await Promise.allSettled(
          this.levelsForm.map(levelForm => {
            const correspondingLevels = [
              ...new Set([
                ...levelForm.parentLevel.level_corresponding.filter(el => !this.nation2ContractLevelsIds.includes(el)),
                ...levelForm.childLevels,
              ]),
            ];
            return updateCorrespondingLevels(levelForm.parentLevel.id, correspondingLevels);
          }),
        );
        const errors = results.filter(res => res.status === "rejected");
        if (!errors.length) {
          successToast(this.$t("Contract levels associated!"));
          this.$emit("contracts-updated");
          return;
        }
        backendErrorSwal(`There have been ${errors.length} errors`);
      } catch (err) {
        backendErrorSwal(err);
      } finally {
        this.submitting = false;
      }
    },
    loadContracts() {
      EmployeeNationalContractService.getAll({
        fields: '',
        expand: 'nationalcontractlevel_set',
      })
        .then((res) => {
          this.contracts = res.results
        })
        .catch((err) => {
          console.log(err, 'error to load the contracts')
        });
    },
  }
};
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 1rem;
}

.header-h {
  height: 4rem;
}
</style>
