var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('national-contracts-comparison-wizard', {
    key: _vm.nccwKey,
    on: {
      "contracts-updated": _vm.onContractsUpdated
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }