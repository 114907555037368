<template>
  <div class="card card-custom">
    <div class="card-body">
      <national-contracts-comparison-wizard :key="nccwKey"
        @contracts-updated="onContractsUpdated"></national-contracts-comparison-wizard>
    </div>
  </div>
</template>

<script>
import NationalContractsComparisonWizard from "@/view/components/wizards/NationalContractsComparisonWizard.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    NationalContractsComparisonWizard,
  },

  data() {
    return {
      nccwKey: 0,
    };
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("Contract Comparison Wizard") }]);
  },

  methods: {
    onContractsUpdated() {
      this.nccwKey++;
    },
  },
};
</script>
